import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "flex justify-center items-center mt-7"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($props.type === 'text')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode($setup["Typography"], { size: "md" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.loadingMessage), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode($setup["Spinner"], { size: $props.spinnerSize }, null, 8 /* PROPS */, ["size"])
            ]))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}