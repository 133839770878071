import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Typography"], {
    size: "xs",
    class: "flex justify-between p-2 flex-wrap"
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", null, "DataHub v" + _toDisplayString($setup.version) + " updated: " + _toDisplayString($setup.date), 1 /* TEXT */),
      _createElementVNode("span", null, "Copyright RE/MAX Europe Regional Services © " + _toDisplayString(new Date().getFullYear()), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }))
}